import { paramsParser } from "../core/paramsParser";
import { Headers } from "./generic";
import { assertSuccess } from "../core/assert";
import axios from "../core/axios";
import type {
  CoverDetails,
  CoverEntity,
  CoverListParams,
} from "../types/cover";
import type { CollectionResult, Result } from "../types/result";
import type { AvailableLanguages } from "../types/locales";

export class Cover {
  static async search(params: CoverListParams = {}, auth?: string) {
    const resp = await axios<CollectionResult<CoverEntity>>(
      `/cover` + paramsParser(params),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    return assertSuccess(resp.data);
  }

  static async get(id: string, expanded: boolean = true, auth?: string) {
    const resp = await axios<Result<CoverEntity>>(
      `/cover/${id}` +
        paramsParser({ includes: expanded ? ["title"] : undefined }),
      {
        headers: auth ? Headers.Bearer(auth) : undefined,
        responseType: "json",
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async update(
    coverId: string,
    template: CoverTemplate,
    version: number,
    auth: string,
  ) {
    const coverTemplate = template.getTemplate();
    coverTemplate.volume = coverTemplate.volume?.trim() ?? null;

    const resp = await axios(`/cover/${coverId}`, {
      method: "PUT",
      headers: { ...Headers.Bearer(auth), ...Headers.JSON },
      data: {
        ...coverTemplate,
        version: version,
      },
    });

    assertSuccess(resp.data);
  }

  static async delete(coverId: string, version: number, auth: string) {
    const resp = await axios(`/cover/${coverId}`, {
      method: "DELETE",
      headers: Headers.Bearer(auth),
      data: { version: version },
    });

    assertSuccess(resp.data);
  }
}

export class CoverTemplate {
  template: CoverDetails;

  constructor() {
    this.template = {
      locale: "en",
      volume: "1",
    };
  }

  getTemplate() {
    return this.template;
  }

  setLocale(locale: AvailableLanguages) {
    this.template.locale = locale;

    return this;
  }

  setVolume(volume: string | null) {
    this.template.volume = volume;

    return this;
  }
}
